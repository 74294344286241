import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import LoadingIndicator from "./components/loading-indicator/LoadingIndicator";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./pages/ErrorFallback";
// import "./styles/main.scss";

// import App from "./App";

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary
    // fallback={<div>Something went wrong. Try Google Reviews</div>}
    FallbackComponent={ErrorFallback}
  >
    <Suspense fallback={<LoadingIndicator />}>
      <App />
    </Suspense>
  </ErrorBoundary>
);
// root.render(<LoadingIndicator />);

// {
/* <ErrorBoundary fallback={<h1>Something went wrong. Please try refreshing the page.</h1>}></ErrorBoundary> */
// }
