import { IsPropertySupported } from "../../utils/functions/IsPropertySupported";
import "./LoadingIndicatorStyles.scss";

const LoadingIndicator = () => {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        minHeight: IsPropertySupported("min-height", "100svh")
          ? "100svh"
          : "100vh",
      }}
    >
      <div
        className="loading-image"
        id="loading-image"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          maxWidth: "204px",
          maxHeight: "204px",
          minWidth: "204px",
          minHeight: "204px",
        }}
      >
        <img
          src="/icons/page-loading.svg"
          alt="loading page"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
      {/* <div className="logo-animation"></div> */}
    </div>
  );
};

export default LoadingIndicator;
